var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer live_content" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "直播活动", name: "activity" } }),
          _c("el-tab-pane", { attrs: { label: "直播举报", name: "report" } }),
        ],
        1
      ),
      _vm.activeName == "activity" ? _c("ActivityList") : _vm._e(),
      _vm.activeName == "report" ? _c("ReportList") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }