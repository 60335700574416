<template>
  <PageContent>
    <el-form label-width="90px">
      <el-form-item label="直播活动:">
        {{ reportInfo.liveName }}
      </el-form-item>
      <el-form-item label="主播ID:">
        {{ reportInfo.liveId }}
      </el-form-item>
      <el-form-item label="举报原因:">
        {{ reportInfo.contentStr }}
      </el-form-item>
      <el-form-item label="图片举证:">
        <template v-for="(item, index) in reportInfo.imageIds.split(',')">
          <img
            :key="index"
            :src="item"
            alt="图片"
            style="width: 100px; height: 100px; margin: 10px;cursor: pointer;"
            @click="showImg(item)"
          >
        </template>
      </el-form-item>
      <el-form-item label="举报人:">
        {{ reportInfo.userId }}
      </el-form-item>
      <el-form-item label="举报时间:">
        {{ dayFormat(reportInfo.createTime) }}
      </el-form-item>
    </el-form>
  </PageContent>
</template>
<script>

import dayjs from 'dayjs'

export default {
  name: 'ReportDetail',
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      reportInfo: {
      }
    }
  },
  mounted() {
    console.log(this.id)
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.live.live_report_detail,
        params: {
          id: this.id
        }
      }).then((res) => {
        const r = res || {}
        if (r.code === 0) {
          this.reportInfo = r.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    showImg(url) {
      window.open(url, '_blank')
    },
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    }
  }

}
</script>
