<template>
  <div class="outer live_content">
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="直播活动"
        name="activity"
      />
      <el-tab-pane
        label="直播举报"
        name="report"
      />
    </el-tabs>
    <ActivityList v-if="activeName == 'activity'" />
    <ReportList v-if="activeName == 'report'" />
  </div>
</template>

<script>
/**
 * 直播页面
 * 用途：直播管理
 */
import ActivityList from './activity/list';
import AccountList from './account/list';
import ReportList from './report/list';

export default {
  name: 'live-manage',
  components: {
    ActivityList,
    AccountList,
    ReportList,

  },
  props: {

  },
  data() {
    return {
      activeName: 'activity',
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>

</style>
