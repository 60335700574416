export const statusArr = [
  {
    value: 0,
    title: '未开始',
  },
  {
    value: 1,
    title: '进行中',
  },
  {
    value: 2,
    title: '已结束',
  },
];

export const statusList = {
  0: '未开始',
  1: '进行中',
  2: '已结束',
};

export const discountsType = {
  1: '满减',
  2: '折扣',
};

export const discountsConditionType = {
  1: '金额',
  2: '件数',
};

export const accountTypeList = [
  {
    id: 3,
    title: '个人',
    value: 3,
  },
  {
    id: 2,
    title: '供应商',
    value: 2,
  },
  {
    id: 1,
    title: '政府',
    value: 1,
  },
];

export const accountTypeObj = {
  3: '个人',
  2: '供应商',
  1: '政府',
};

export const statusListAccount = [
  {
    value: '1',
    label: '待审核',
  },
  {
    value: '2',
    label: '审核通过',
  },
  {
    value: '3',
    label: '审核未通过',
  },
];

export const ACTIVE_TYPE_MAP = {
  1: '秒杀',
  2: '直降',
  5: '新人专享',
  6: '跨店满减',
};
