<template>
  <div>
    <div class="outer">
      <search-panel
        show-btn
        flex
        @getList="getListChange"
      >
        <div>
          <p>主播昵称</p>
          <el-select
            v-model="searchList.liverName"
            placeholder="请选择主播"
            clearable
            size="mini"
          >
            <el-option
              v-for="item in anchorList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            />
          </el-select>
        </div>
        <div>
          <p>举报原因</p>
          <el-select
            v-model="searchList.reportType"
            placeholder="请选择举报原因"
            clearable
            size="mini"
          >
            <el-option
              v-for="item in reasonList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <p>举报账号</p>
          <el-input
            v-model.trim="searchList.reportId"
            placeholder="请输入举报人账号"
            clearable
            size="mini"
          />
        </div>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="liveName"
          label="直播名称"
          width="120"
        />
        <el-table-column
          prop="liverName"
          label="主播昵称"
          width="120"
        />
        <el-table-column
          prop="contentStr"
          label="举报原因"
        />
        <el-table-column
          prop="userId"
          label="举报账号"
          width="140"
        />
        <el-table-column
          prop="createTime"
          label="举报时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="readHandle(scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 查看详情弹窗 -->
    <el-dialog
      v-if="dialogFormVisible"
      title="举报详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <ReportDetail :id="reportId" />
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ReportDetail from './detail.vue';

export default {
  name: 'report-list',
  components: {
    ReportDetail,
  },
  data() {
    return {
      searchList: {
        liverName: null, // 主播昵称
        reportType: null, // 举报原因
        reportId: null, // 主播账号
      },
      anchorList: [], // 主播昵称列表
      reasonList: [
        { value: 1, label: '色情，暴力，赌博' },
        { value: 2, label: '非法政治信息' },
        { value: 3, label: '宗教信仰' },
        { value: 4, label: '出售假冒商品' },
        { value: 5, label: '恶意广告' },
        { value: 6, label: '其他' },
      ],
      tableData: [], // 举报列表
      loading: false,
      dialogFormVisible: false,
      reportId: '', // 详情id
      pagination: {
        page: 1,
        page_count: 10,
        page_total: 1,
        item_total: 0,
      },

    };
  },
  mounted() {
    this.getList();
    this.getAnchorList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val;
      this.pagination.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    getListChange() {
      this.pagination.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let params = {
        pageSize: this.pagination.page_count,
        currentPage: this.pagination.page,
        ...this.searchList,
      };
      this.$axios.post(
        this.$api.live.live_report_list,
        params,
      ).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          this.tableData = res.data.records;
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },
    readHandle(data) {
      this.reportId = data.id;
      this.dialogFormVisible = true;
    },
    // 获取主播列表
    getAnchorList() {
      this.$axios.post(this.$api.live.anchor_list, {
        type: 1,
      }).then((res) => {
        if (res.code === 0) {
          this.anchorList = res.data || [];
        } else {
          this.$message({ type: 'error', message: res.error_msg });
        }
      });
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },

  },

};
</script>
