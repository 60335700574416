<template>
  <div class="live_activity_content">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <p>直播状态</p>
          <el-select
            v-model="searchParams.liveStatus"
            clearable
            size="mini"
            placeholder="请选择直播状态"
          >
            <el-option
              v-for="item in statusArr"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            />
          </el-select>
        </div>

        <div>
          <p>直播活动名称</p>
          <el-input
            v-model.trim="searchParams.name"
            placeholder="请输入直播活动名称/主播名称/房间号"
            size="mini"
            clearable
            style="width: 300px"
          />
        </div>
        <el-button
          slot="button-right"
          size="mini"
          type="primary"
          plain
          @click="addLiveActivity('add')"
        >
          新增直播活动
        </el-button>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          label="活动信息"
          min-width="210"
        >
          <template slot-scope="scope">
            <div class="goods_message">
              <div class="left">
                <img :src="scope.row.coverImageId" />
              </div>
              <div class="right">
                <div class="title">
                  {{ scope.row.title }}
                </div>
                <div class="id">
                  {{ scope.row.content }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="主播信息"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.actorName }} ({{ scope.row.actorId }})
          </template>
        </el-table-column>
        <el-table-column
          label="直播状态"
          min-width="110"
        >
          <template slot-scope="scope">
            {{ statusList[scope.row.liveStatus] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="audit_time"
          label="直播时间"
          min-width="210"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.liveStartTime) }}--{{ dayFormat(scope.row.liveEndTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsSum"
          label="直播商品"
          min-width="110"
        />
        <el-table-column
          prop="liveCreater"
          label="操作人"
          min-width="110"
        />
        <el-table-column
          label="操作"
          width="210"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toControl(scope.row)"
            >
              直播控制台
            </el-button>

            <el-divider direction="vertical" />
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                更多
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="composeValue('删除',scope.row.id)">
                  删除
                </el-dropdown-item>
                <el-dropdown-item
                  :disabled="scope.row.liveStatus != 0"
                  :command="composeValue('编辑',scope.row.id)"
                >
                  编辑
                </el-dropdown-item>
                <el-dropdown-item :command="composeValue('分享',scope.row.id)">
                  分享
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="changFunctionNum"
      :visible.sync="isShowWindows"
      width="600px"
      :before-close="() => isShowWindows = false"
      :close-on-click-modal="false"
    >
      <div>
        <div
          v-if="changFunctionNum=='分享' && JSON.stringify(shareMsg) != '{}'"
          class="share_content"
        >
          <div class="image_path">
            <img
              class="pushimg"
              :src="shareMsg.imagePath"
              alt=""
            />
            <el-button
              class="btn"
              type="text"
              size="mini"

              @click="downloadImg('imagePath')"
            >
              保存图片
            </el-button>
          </div>
          <div class="image_path">
            <img
              class="pushimg"
              :src="shareMsg.wxminiImage"
              alt=""
            />
            <el-button
              class="btn"
              type="text"
              size="mini"
              @click="downloadImg('wxminiImage')"
            >
              保存图片
            </el-button>
          </div>
          <div>
            {{ shareMsg.shareUrl }}
            <el-button
              class="btn"
              type="primary"
              size="mini"
              :data-clipboard-text="shareMsg.shareUrl"
              @click="copy()"
            >
              复制链接
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */

import dayjs from 'dayjs';
import Clipboard from 'clipboard';
import { statusArr, statusList } from '../config';

export default {
  name: 'activity-list',
  components: {
    // scheduleDialog,
  },
  props: {

  },
  data() {
    return {
      searchParams: {
        name: '',
        liveStatus: '',
      },
      statusArr,
      statusList,
      goodsId: '',
      tableData: [],
      loading: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },
      isShowWindows: false,
      changFunctionNum: null,
      shareMsg: {

      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val;
      this.pagination.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    getListChange() {
      this.pagination.page = 1;
      this.getList(1, this.searchParams);
    },

    getList(index, searchParams) { // index为页面当前页数 searchParams为搜索参数
      let _this = this;
      _this.loading = true;
      _this.$axios({
        method: 'POST',
        url: _this.$api.live.live_events_list,
        data: {
          ...searchParams,
          currentPage: _this.pagination.page || index,
          pageSize: _this.pagination.page_count,
        },
      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.tableData = res.data.records;
        } else {
          _this.tableData = [];
          _this.pagination.item_total = 0;
        }
        _this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    // 新建直播活动
    addLiveActivity(type, id) {
      this.$router.push({
        path: '/nb/marketingTools/live/addAndEdit',
        query: {
          type,
          id: type == 'edit' ? id : null,
        },
      });
    },

    toControl(row) {
      this.$router.push({
        path: '/nb/marketingTools/live/control',
        query: {
          id: row.id, // 直播活动id
          liveId: row.liveId, // 主播id
        },
      });
    },

    getDetail(id) {
      this.$axios(this.$api.live.live_events_detail, { id })
        .then((res) => {

        });
    },

    composeValue(item, id) {
      return {
        button: item,
        id,
      };
    },

    // 复制功能
    copy() {
      let clipboard = new Clipboard('.btn');
      clipboard.on('success', (e) => {
        this.$message.success('复制成功');
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy();
      });
    },

    // 下载图片
    downloadImg(type) {
      if (type == 'imagePath') {
        window.open(
          this.shareMsg.imagePath,
          '_target',
        );
      } else {
        window.open(
          this.shareMsg.wxminiImage,
          '_target',
        );
      }
    },
    // 分享
    onDialog(val, id) {
      this.isShowWindows = true;
      this.changFunctionNum = val;
      if (val === '分享') {
        this.$axios.post(this.$api.liveShare.share_msg, {
          itemId: id,
          itemType: 10,
          tokenType: 1,
        })
          .then((res) => {
            if (res.code === 0) {
              this.shareMsg = {
                ...res.data,
              };
            }
          }).catch((error) => {
            console.log(error);
          });
      }
    },

    onDelete(id) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios(`${this.$api.live.live_events_delete}?id=${id}`)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('删除成功');
              this.getList();
            }
          });
      });
    },

    // 更多操作
    handleCommand(command) {
      const { button, id } = command;
      switch (button) {
        case '编辑':
          this.addLiveActivity('edit', id);
          break;
        case '删除':
          this.onDelete(id);
          break;
        case '分享':
          this.onDialog(button, id);
          break;
        default:
          break;
      }
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },

  },
};
</script>
<style lang="scss" scoped>
.live_activity_content {
    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .pushtxt {
      margin-bottom: 20px;
    }
    .pushimg {
      width: 200px;
    }
    .share_content {
      text-align: center;
      .image_path {

        display: inline-block;
        margin: 0 20px 20px;
        img {
          display: block;
          border: 1px solid #f7f3f3;
          width: 200px;
          height: 200px;
        }
      }
    }

}
</style>
<style lang="scss">
  .goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }

</style>
