var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "live_activity_content" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getListChange },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("直播状态")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "请选择直播状态",
                      },
                      model: {
                        value: _vm.searchParams.liveStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "liveStatus", $$v)
                        },
                        expression: "searchParams.liveStatus",
                      },
                    },
                    _vm._l(_vm.statusArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.title, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("直播活动名称")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请输入直播活动名称/主播名称/房间号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchParams.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchParams,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchParams.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "button-right",
                    size: "mini",
                    type: "primary",
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addLiveActivity("add")
                    },
                  },
                  slot: "button-right",
                },
                [_vm._v(" 新增直播活动 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "活动信息", "min-width": "210" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "goods_message" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("img", {
                              attrs: { src: scope.row.coverImageId },
                            }),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(scope.row.title) + " "),
                            ]),
                            _c("div", { staticClass: "id" }, [
                              _vm._v(" " + _vm._s(scope.row.content) + " "),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "主播信息", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.actorName) +
                            " (" +
                            _vm._s(scope.row.actorId) +
                            ") "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "直播状态", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.statusList[scope.row.liveStatus]) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "audit_time",
                  label: "直播时间",
                  "min-width": "210",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dayFormat(scope.row.liveStartTime)) +
                            "--" +
                            _vm._s(_vm.dayFormat(scope.row.liveEndTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsSum",
                  label: "直播商品",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "liveCreater",
                  label: "操作人",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "210", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toControl(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 直播控制台 ")]
                        ),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommand } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(" 更多 "),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.composeValue(
                                        "删除",
                                        scope.row.id
                                      ),
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      disabled: scope.row.liveStatus != 0,
                                      command: _vm.composeValue(
                                        "编辑",
                                        scope.row.id
                                      ),
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.composeValue(
                                        "分享",
                                        scope.row.id
                                      ),
                                    },
                                  },
                                  [_vm._v(" 分享 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.page_count,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.item_total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.changFunctionNum,
            visible: _vm.isShowWindows,
            width: "600px",
            "before-close": () => (_vm.isShowWindows = false),
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowWindows = $event
            },
          },
        },
        [
          _c("div", [
            _vm.changFunctionNum == "分享" &&
            JSON.stringify(_vm.shareMsg) != "{}"
              ? _c("div", { staticClass: "share_content" }, [
                  _c(
                    "div",
                    { staticClass: "image_path" },
                    [
                      _c("img", {
                        staticClass: "pushimg",
                        attrs: { src: _vm.shareMsg.imagePath, alt: "" },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadImg("imagePath")
                            },
                          },
                        },
                        [_vm._v(" 保存图片 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "image_path" },
                    [
                      _c("img", {
                        staticClass: "pushimg",
                        attrs: { src: _vm.shareMsg.wxminiImage, alt: "" },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadImg("wxminiImage")
                            },
                          },
                        },
                        [_vm._v(" 保存图片 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm._v(" " + _vm._s(_vm.shareMsg.shareUrl) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            "data-clipboard-text": _vm.shareMsg.shareUrl,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy()
                            },
                          },
                        },
                        [_vm._v(" 复制链接 ")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }