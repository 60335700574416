<template>
  <div class="add-account">
    <el-dialog
      :title="type == 'add' ? '新增直播账号' : '编辑直播账号'"
      width="40%"
      :close-on-click-modal="false"
      :visible.sync="editStatus"
      @close="handleBtn(0)"
    >
      <el-form
        ref="accountForm"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item
          label="用户账号"
          prop="mobile"
        >
          <el-input
            v-model="form.mobile"
            :disabled="true"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="直播房间号"
          prop="roomId"
        >
          <el-input
            v-model="form.roomId"
            :disabled="!isEdit"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号类型">
          <el-radio-group
            v-model="form.actorType"
            @change="actorChange"
          >
            <el-radio
              v-for="(item) in accountTypeList"
              :key="'showType'+item.id"
              :label="item.value"
              :disabled="item.value !== 3 && type === 'add'"
            >
              {{ item.title }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="用户头像"
          prop="avatarImagePath"
        >
          <el-upload
            v-model="form.avatarImagePath"
            v-loading="loading"
            :class="['avatar-uploader', !form.avatarImagePath ? 'imgBorder':'']"
            name="multipartFile"
            :action="imgUploadUrl"
            :multiple="false"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="handleAvatarSuccess"
          >
            <div
              v-if="form.avatarImagePath"
              class="avatar"
            >
              <img :src="form.avatarImagePath">
            </div>
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="直播昵称"
          prop="nickname"
        >
          <el-input
            v-model="form.nickname"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleBtn(0)">取 消</el-button>
        <el-button
          type="primary"
          @click="handleBtn(1)"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { accountTypeList, statusListAccount, accountTypeObj } from '../../config'

export default {
  name: 'EditAccount',
  props: {
    type: {
      type: String,
      default: 'add'
    },
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      accountTypeList, // 账号类型
      imgUploadUrl: this.$api.nb_common.newUpload,
      editStatus: this.show,
      supplierList: [],

      form: this.value,
      rules: {
        suppliersId: [
          { required: true, message: '请选择供应商', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请输入直播昵称', trigger: 'blur' },
          { min: 1, max: 10, message: '最多不能超过10个字', trigger: 'blur' }
        ],
        roomId: [
          { required: true, message: '请输入直播房间号', trigger: 'blur' }
        ],
        avatarImagePath: [
          { required: true, message: '请上传用户头像', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '需要用户账号', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    isEdit() {
      return this.type === 'edit'
    }

  },
  watch: {
    show(val) {
      this.editStatus = val
    },
    value(val) {
      this.form = { ...val }
    }
  },
  created() {
  },
  methods: {
    actorChange() {
      this.$refs['accountForm'].clearValidate()
    },

    handleBtn(val) {
      if (val === 0) { // 取消
        this.$refs['accountForm'].resetFields()
        this.$emit('cancel', false)
      }

      if (val === 1) { // 确定添加
        console.log(this.form)
        this.$refs['accountForm'].validate((valid) => {
          if (valid) {
            this.form.type = this.type
            this.$emit('submit', this.form)
          } else {
            return false
          }
        })
      }
    },

    // 图片上传
    beforeUpload() {
      this.loading = true
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.form.avatarImagePath = res.data.fileUrl
        this.loading = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.imgBorder {
  border: 1px dashed #DCDFE6;
}

.avatar-uploader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.avatar-uploader .el-upload {
    border: 1px solid #DCDFE6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #DCDFE6;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 80px;
      height: 80px;
      display: block;
    }
  }
</style>
