var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "search-panel",
            {
              attrs: { "show-btn": "", flex: "" },
              on: { getList: _vm.getListChange },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("主播昵称")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择主播",
                        clearable: "",
                        size: "mini",
                      },
                      model: {
                        value: _vm.searchList.liverName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchList, "liverName", $$v)
                        },
                        expression: "searchList.liverName",
                      },
                    },
                    _vm._l(_vm.anchorList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nickname, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("举报原因")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择举报原因",
                        clearable: "",
                        size: "mini",
                      },
                      model: {
                        value: _vm.searchList.reportType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchList, "reportType", $$v)
                        },
                        expression: "searchList.reportType",
                      },
                    },
                    _vm._l(_vm.reasonList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("举报账号")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入举报人账号",
                      clearable: "",
                      size: "mini",
                    },
                    model: {
                      value: _vm.searchList.reportId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchList,
                          "reportId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchList.reportId",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": {
                  background: "#D9DDE1",
                  color: "#0D0202",
                  "font-weight": 400,
                },
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "liveName", label: "直播名称", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "liverName", label: "主播昵称", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "contentStr", label: "举报原因" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userId", label: "举报账号", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "举报时间", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dayFormat(scope.row.createTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.readHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.page_count,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.item_total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "举报详情",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [_c("ReportDetail", { attrs: { id: _vm.reportId } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }