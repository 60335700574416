var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageContent",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "90px" } },
        [
          _c("el-form-item", { attrs: { label: "直播活动:" } }, [
            _vm._v(" " + _vm._s(_vm.reportInfo.liveName) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "主播ID:" } }, [
            _vm._v(" " + _vm._s(_vm.reportInfo.liveId) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "举报原因:" } }, [
            _vm._v(" " + _vm._s(_vm.reportInfo.contentStr) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "图片举证:" } },
            [
              _vm._l(
                _vm.reportInfo.imageIds.split(","),
                function (item, index) {
                  return [
                    _c("img", {
                      key: index,
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        margin: "10px",
                        cursor: "pointer",
                      },
                      attrs: { src: item, alt: "图片" },
                      on: {
                        click: function ($event) {
                          return _vm.showImg(item)
                        },
                      },
                    }),
                  ]
                }
              ),
            ],
            2
          ),
          _c("el-form-item", { attrs: { label: "举报人:" } }, [
            _vm._v(" " + _vm._s(_vm.reportInfo.userId) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "举报时间:" } }, [
            _vm._v(
              " " + _vm._s(_vm.dayFormat(_vm.reportInfo.createTime)) + " "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }