<template>
  <div class="live-account">
    <page-content
      padding="0px"
    >
      <search-panel
        flex
        show-btn
        @getList="getListHandle"
      >
        <div>
          <p>直播房间号</p>
          <el-input
            v-model.trim="searchList.roomId"
            clearable
            placeholder="请输入直播房间号"
            size="mini"
          />
        </div>
        <div>
          <p>直播昵称</p>
          <el-input
            v-model.trim="searchList.nickname"
            clearable
            placeholder="直播昵称"
            size="mini"
          />
        </div>
        <div>
          <p>账号类型</p>
          <el-select
            v-model="searchList.actorType"
            clearable
            placeholder="账号类型"
            size="mini"
          >
            <el-option
              v-for="(item, index) in accountTypeList"
              :key="index"
              :label="item.title"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <p>时间区间</p>
          <el-date-picker
            v-model="time_interval"
            type="datetimerange"
            range-separator="至 "
            size="mini"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="timestamp"
          />
        </div>
        <div>
          <p>审核状态</p>
          <el-select
            v-model="searchList.applyState"
            clearable
            placeholder="审核状态"
            size="mini"
          >
            <el-option
              v-for="(item, index) in statusListAccount"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <!-- <el-button
          slot="button-right"
          size="mini"
          @click="addAccount(0)"
        >
          新增账号
        </el-button> -->
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        :header-row-style="{'font-size': '12px', 'font-weight': 100, 'color': '#0D0202'}"
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      >
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        />
        <el-table-column
          prop="avatarImagePath"
          label="主播头像"
          width="130"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.avatarImagePath"
              style="width: 80px; height: 80px;"
              alt=""
            >
          </template>
        </el-table-column>
        <el-table-column
          label="房间号"
          prop="roomId"
          min-width="100"
        />
        <el-table-column
          label="直播昵称"
          prop="nickname"
          width="140"
        />
        <el-table-column
          label="账号类型"
        >
          <template slot-scope="scope">
            {{ accountTypeObj[scope.row.actorType] }}
          </template>
        </el-table-column>
        <el-table-column
          label="审核状态"
          prop="status"
        >
          <template slot-scope="scope">
            <el-link
              link=""
              :type="accountStatus(scope.row.applyState)['type']"
            >
              {{ accountStatus(scope.row.applyState)['value'] }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="添加时间"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="160"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="addAccount(1, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="delItem(scope.row)"
            >
              删除
            </el-button>
            <el-button
              type="text"
              @click="accountHandle(getStatus(scope.row.status)['status'], scope.row.id)"
            >
              {{ getStatus(scope.row.status)['title'] }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <edit-account
      :type="handleType"
      :value="formValue"
      :show="isFormShow"
      @cancel="formHandle"
      @submit="getFormValue"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import qs from 'qs'
import { accountTypeList, statusListAccount, accountTypeObj } from '../config'
import editAccount from './components/editAccount'

export default {
  name: 'AccountList',
  components: {
    editAccount
  },
  data() {
    return {
      searchList: {
        roomId: '',
        nickname: '',
        actorType: '', // 账号类型：（1政府, 2供应商, 3个人）
        applyState: '', // 审核状态
        startTime: '', //
        endTime: '' //
      },
      accountTypeList,
      accountTypeObj,
      statusListAccount,
      time_interval: [],
      pagination: {
        page: 1,
        page_count: 10,
        page_total: 1,
        item_total: 0
      },
      loading: false,
      tableData: [],
      formValue: {

      },
      isFormShow: false,
      handleType: 'add',

      checkInfo: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getListHandle() {
      this.pagination.page = 1
      this.getList()
    },
    getFormValue(val) {
      if (val) {
        this.$confirm('确认提交并通过该直播账号的信息？', '确认并通过审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _url = val.type === 'add' ? this.$api.live.live_account_add : this.$api.live.live_account_edit
          this.$axios.post(_url, {
            ...val
          }).then(res => {
            if (res.code === 0) {
              this.$message.success(`${val.type === 'add' ? '新增直播账号成功' : '编辑直播账号成功'}`)
              this.isFormShow = false
              this.getList()
            }
          })
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    formHandle(val) {
      this.isFormShow = !!val
    },
    addAccount(val, row) {
      if (val === 0) { // 新增
        this.$prompt('该用户账号为用户在网上农博的账号', '请输入用户账号', {
          closeOnClickModal: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^1[3456789]\d{9}$/, // 18627976612
          inputErrorMessage: '手机号格式不正确'
        }).then(({ value }) => {
          let params = {
            mobile: value
          }
          this.$axios.post(this.$api.live.live_account_check, qs.stringify(params)).then((res) => {
            let _data = res.data
            this.handleType = 'add'
            this.formValue = {}
            this.formValue = {
              mobile: _data.mobile,
              avatarImagePath: _data.headPic,
              nickname: _data.nickname,
              roomId: _data.roomId,
              userId: _data.userId,
              actorType: 3 // 个人
            }
            this.isFormShow = true
          })
        })
      }

      if (val === 1) {
        // 编辑账号
        this.handleType = 'edit'
        this.$axios(this.$api.live.live_account_detail, {
          params: {
            id: row.id
          }
        }).then((res) => {
          if (res.code === 0) {
            this.formValue = {
              ...res.data
            }
            this.isFormShow = true
          }
        })
      }
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },

    getList(index) {
      let _this = this
      this.loading = true
      this.$axios.post(this.$api.live.live_account_list, {
        currentPage: _this.pagination.page || index,
        pageSize: _this.pagination.page_count,
        ..._this.searchList,
        startTime: this.time_interval ? this.time_interval[0] : '',
        endTime: this.time_interval ? this.time_interval[1] : ''
      }).then((res) => {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      })
        .catch(function(error) {
          console.log(error)
        })
    },
    showItem(row) {
      this.$axios.post(this.$api.cate.isshow, {
        id: row.id,
        is_show: row.is_show ? 1 : 0
      }).then((res) => {
        let r = res.data
        if (r.code === 0) {
          this.$message({ type: 'success', message: '操作成功！' })
        } else {
          this.getList()
          this.$message.error(r.msg)
        }
      })
    },
    // 获取操作类型
    getStatus(val) {
      if (val === 1) { // 正常状态
        return {
          title: '禁播',
          status: 2
        }
      }
      if (val === 2) { // 禁用状态
        return {
          title: '开启',
          status: 1
        }
      }
    },
    accountHandle(row, idVal) {
      let _tips = ''
      let _title = ''

      if (row === 1) { // 账号正常执行禁播操作
        _tips = '请确认是否禁播此账号?'
        _title = '禁播账号提示'
      }

      if (row === 2) { // 账号禁用执行开启操作
        _tips = '请确认是否开启此账号?'
        _title = '开启账号提示'
      }
      this.$confirm(_tips, _title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.live.live_account_forbid, qs.stringify({
          id: idVal,
          status: row
        })).then((res) => {
          if (res.code === 0) {
            this.getList()
          }
        })
      }).catch(() => {})
    },
    delItem(row) {
      this.$confirm('请确认是否删除该主播账号?', '删除账号提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id // 主键id
        }
        this.$axios.post(this.$api.live.live_account_delete, qs.stringify(params)).then((res) => {
          if (res.code === 0) {
            this.$message({ type: 'success', message: '账号删除成功' })
            this.getList()
          }
        })
      }).catch(() => {})
    },

    checkHandle(row) {
      this.checkInfo = row
      this.$refs.checkDialog.dialogVisible = true
    },

    accountStatus(val = 0) {
      if (val === 1) {
        return {
          type: 'primary',
          value: '待审核'
        }
      }

      if (val === 2) {
        return {
          type: 'success',
          value: '审核通过'
        }
      }

      if (val === 3) {
        return {
          type: 'danger',
          value: '审核未通过'
        }
      }

      return {
        type: '',
        value: 'null'
      }
    }

  }
}
</script>

<style>
.el-table__expanded-cell{
  padding:10px 0 0 49px;
}
</style>
