var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-account" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type == "add" ? "新增直播账号" : "编辑直播账号",
            width: "40%",
            "close-on-click-modal": false,
            visible: _vm.editStatus,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editStatus = $event
            },
            close: function ($event) {
              return _vm.handleBtn(0)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "accountForm",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户账号", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, size: "mini" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播房间号", prop: "roomId" } },
                [
                  _c("el-input", {
                    attrs: { disabled: !_vm.isEdit, size: "mini" },
                    model: {
                      value: _vm.form.roomId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roomId", $$v)
                      },
                      expression: "form.roomId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.actorChange },
                      model: {
                        value: _vm.form.actorType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "actorType", $$v)
                        },
                        expression: "form.actorType",
                      },
                    },
                    _vm._l(_vm.accountTypeList, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: "showType" + item.id,
                          attrs: {
                            label: item.value,
                            disabled: item.value !== 3 && _vm.type === "add",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户头像", prop: "avatarImagePath" } },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      class: [
                        "avatar-uploader",
                        !_vm.form.avatarImagePath ? "imgBorder" : "",
                      ],
                      attrs: {
                        name: "multipartFile",
                        action: _vm.imgUploadUrl,
                        multiple: false,
                        "show-file-list": false,
                        "before-upload": _vm.beforeUpload,
                        "on-success": _vm.handleAvatarSuccess,
                      },
                      model: {
                        value: _vm.form.avatarImagePath,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "avatarImagePath", $$v)
                        },
                        expression: "form.avatarImagePath",
                      },
                    },
                    [
                      _vm.form.avatarImagePath
                        ? _c("div", { staticClass: "avatar" }, [
                            _c("img", {
                              attrs: { src: _vm.form.avatarImagePath },
                            }),
                          ])
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直播昵称", prop: "nickname" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.form.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nickname", $$v)
                      },
                      expression: "form.nickname",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleBtn(0)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBtn(1)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }